import { render, staticRenderFns } from "./layout-product-gallery.vue?vue&type=template&id=06259936&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&xmlns%3Av-touch=http%3A%2F%2Fwww.w3.org%2F1999%2Fxhtml&"
import script from "./layout-product-gallery.vue?vue&type=script&lang=js&"
export * from "./layout-product-gallery.vue?vue&type=script&lang=js&"
import style0 from "vue-phone-number-input/dist/vue-phone-number-input.css?vue&type=style&index=0&lang=css&"
import style1 from "./layout-product-gallery.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./layout-product-gallery.vue?vue&type=style&index=2&id=06259936&lang=scss&scoped=true&"
import style3 from "./layout-product-gallery.vue?vue&type=style&index=3&lang=scss&"
import style4 from "./layout-product-gallery.vue?vue&type=style&index=4&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06259936",
  null
  
)

export default component.exports