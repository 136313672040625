<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform" xmlns:v-touch="http://www.w3.org/1999/xhtml">
  <div class="layout-product-gallery">
    <div class="gallery">
      <div class="minies">
        <div class="vuebar-element" v-bar>
            <div>
              <div v-if="items.length>1"
                      v-for="(mini, index) in items"
                      :key="index"
                      :class="[{ active: index === current }]"
                      class="mini"
                      @click="current = index"
              >
                <div v-if="window.width<767" class="dot"></div>
                <img v-else class="abs" :src="mini.imgdetailsmall" :alt="code+'-mini-'+index" />

              </div>
              <div v-if="videourl" class="videoItem" @click="videoPopupOpened = true">
                <img v-if="window.width>766" :src="imgdetail" :alt="code+'-video'" />
                <span class="videoPlayButton">
                  <img src="@/assets/icons/play.svg" alt="play" />
                </span>
                <span class="bgmask"></span>
              </div>
            </div>
        </div>

        <!--<div class="mini active video-consult" @click="videoPopupOpened = true">
          <div class="icon"><img src="@/assets/icons/webcam.svg" alt="" /></div>
          <div class="name">
            <b>order a video</b><br />
            consultation
          </div>
        </div>-->

        <div class="mini active video-consult">
          <v-popover
                  trigger="manual"
                  :open="isOpenVideoConsult"
                  offset="16"
                  :auto-hide="true"
                  ariaId="videoconsult-block"
                  :popoverClass="{videoConsultSuccess: isOpenVideoConsultSuccess}"
          >
            <div @click='isOpenVideoConsult = !isOpenVideoConsult, isOpenVideoConsultSuccess = false'>
              <div class="icon">
                <img src="@/assets/icons/webcam.svg" alt="webcam" />
              </div>
              <div class="name">
                <span v-html="this.$store.state.langs.VIDEO_CONSULT"></span>
              </div>
            </div>


            <template slot="popover">
              <div v-if="!isOpenVideoConsultSuccess" class="popover-head">
                {{ this.$store.state.langs.ORDER_VIDEO_CONSULT_HEAD_TEXT }}
              </div>
              <form v-if="!isOpenVideoConsultSuccess" class="ask-videoconsult"
                    @submit.prevent="onSubmit('isOpenVideoConsultSuccess')">
                <div class="popover-videoconsult-inputBlock">

                  <vue-phone-number-input
                      v-model="phone"
                      :defaultCountryCode="lang.toUpperCase()"
                      required
                      @update="updatePhoneNumber"
                      error-color="red"
                      placeholder="Enter Mobile Number"
                  />

                  <!-- <input v-model="phone" name="phone"  type="phone" :placeholder="this.$store.state.langs.ENTER_YOUR_PHONE" /> -->


                </div>
                <div class="popover-footer popover-videoconsult-footer">
                  <ui-button fill>
                    <span>{{ this.$store.state.langs.CALL_ME_TEXT }}</span>
                    <img src="/images/icons/noty-icon.svg" alt="noty"/>
                  </ui-button>
                </div>
                <input type="hidden" name="header" :value="this.$store.state.langs.ORDER_VIDEO_CONSULT_HEAD_TEXT" />
              </form>
              <div v-if="isOpenVideoConsultSuccess" class="successVideoconsult">
                <div class="popover-head">
                  <img src="/images/ok.svg" alt="ok"/>
                </div>
                <div class="popover-subtitle" v-html="this.$store.state.langs.THANKS"></div>
                <div class="popover-footer popover-videoconsult-footer">
                  <ui-button @click='isOpenVideoConsult = !isOpenVideoConsult' fill>{{ this.$store.state.langs.CLOSE_WINDOW }}</ui-button>
                </div>
              </div>
            </template>
          </v-popover>
        </div>

      </div>

      <div class="photo">
        <span v-if="dir3d" class="button-view-3d" @click="modelViewerPopupOpened = true">
          <transition name="slide-fade">
            <span v-if="vtext3d">{{ this.$store.state.langs.WATGH3D }} &nbsp;&nbsp;</span>
          </transition>
          <svg width="20" height="20" class="svg-icon">
          <path d="M8.499,0L5.583,1.718L6.26,2.893l2.239-1.318l2.242,1.318l0.677-1.174L8.499,0z M15.653,14.217v-2.452h1.346v3.233
	l-3.077,1.811l-0.675-1.175L15.653,14.217z M1.346,14.217v-2.452H0v3.233l3.078,1.811l0.678-1.176L1.346,14.217z M1.346,6.578v2.043
	H0V5.004l2.75-1.618l0.677,1.176L2.011,5.395l2.044,1.203L3.378,7.773L1.346,6.578z M13.57,4.559l1.418,0.835l-2.046,1.204
	l0.678,1.176l2.033-1.196v2.044H17V5.004l-2.751-1.62L13.57,4.559z M8.499,9.213l2.867-1.687l0.677,1.176l-2.872,1.69v3.363H7.825
	v-3.363L4.993,8.724l0.678-1.175L8.499,9.213z M9.173,18.031V15.63H7.826v2.401L6.26,17.109l-0.677,1.173L8.499,20l2.917-1.716
	l-0.677-1.175L9.173,18.031z"></path>
        </svg>
        </span>
        <div
          :style="{
            transform: `translate3d(calc(100% * ${current} * -1),0,0)`
          }"
          class="previews"
          v-touch:swipe="swipeHandler"
          v-viewer="viewerOptions"
        >
            <div v-for="(item, index) in items" :key="index" class="preview">
              <img :data-href="item.imgdetail" v-if="window.width<767" class="viewers" :src="item.imgdetailmobile" :alt="index" />
              <img :data-href="item.imgdetail" v-else class="viewers" :src="item.imgdetailsmallbig" :alt="index" />
            </div>
        </div>
      </div>
    </div>
    <portal v-if="videoPopupOpened" to="popup">
      <div class="video-popup abs">
        <div class="overlay abs" @click="videoPopupOpened = false"></div>
        <div class="close" @click="videoPopupOpened = false"><img src="@/assets/icons/close-white.svg" alt="close" /></div>
        <div class="video">
          <iframe width="560" height="315" :src="videourl" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </portal>

    <portal v-if="modelViewerPopupOpened" to="popup">
      <div class="model-viewer-popup">
        <span class="closeWin" @click="modelViewerPopupOpened = false"></span>

        <model-viewer
            v-if="dir3d"
            id="model-viewer"
            :ios-src="'/images/ar/'+dir3d+'/'+dir3d+'.usdz'"
            :src="'/images/ar/'+dir3d+'/scene.gltf'"
            ar
            shadow-intensity="1"
            loading="lazy"
            camera-controls touch-action="pan-y">
            <div id="lazy-load-poster" slot="poster">
              <img src="/images/loading51.gif">
            </div>
        </model-viewer>


      </div>
    </portal>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
//import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
    components: { 'vue-phone-number-input': VuePhoneNumberInput },
    props: {
        dir3d: "",
        code: "",
        videourl: null,
        imgdetail: "",
        imgdetailsmall: "",
        imgdetailsmallbig: "",
        imgdetailmobile: "",
        morephoto: [],
        size: "",
        img: "",
        name: "",
        price: "",
        oldprice: "",
        percent: "",
    },
  data() {
    return {
      vtext3d: true,
      viewerOptions: {
        movable: false,
        rotatable: false,
        scalable: false,
        url: 'data-href',
        title: [1, (image, imageData) => `${image.alt}`]
      },
      videoPopupOpened: false,
      modelViewerPopupOpened: false,
      current: 0,
      items: [],
      isOpenVideoConsult: false,
      isOpenVideoConsultSuccess: false,
      lang: this.$store.state.langs.LANGUAGE_ID,
      phone:null,
      updatePhoneNumber:null,
      window: {
        width: 0,
        height: 0
      }
    }
  },
  mounted() {
    this.init();
    this.initSwiper();
    setTimeout(() => {
      this.vtext3d=false;
    },3000);
  },
  methods: {
    init() {
      //const items = this.$el.querySelectorAll('.hidden > *')
      let itemDetail = {};
      itemDetail.imgdetailsmall = this.imgdetailsmall;
      itemDetail.imgdetailsmallbig = this.imgdetailsmallbig;
      itemDetail.imgdetailmobile = this.imgdetailmobile;
      itemDetail.imgdetail = this.imgdetail;
      this.items.push(itemDetail);

      if(this.morephoto && this.morephoto.length) {
        this.morephoto.forEach((value, index) => {
          let itemMorePhoto = {};
          itemMorePhoto.imgdetailsmall = value.SMALL;
          itemMorePhoto.imgdetailsmallbig = value.SMALL_BIG;
          itemMorePhoto.imgdetailmobile = value.MOBILE;
          itemMorePhoto.imgdetail = value.SRC;
          this.items.push(itemMorePhoto);
        });
      }
    },
    initSwiper() {
      const Hammer = require('hammerjs')
      const stage = this.$el
      const hammertime = new Hammer(stage)
        hammertime.on('swipeleft', ev => {
          //this.next()
        })
        hammertime.on('swiperight', ev => {
          //this.prev()
        })
    },
    next() {
      if (this.current !== this.items.length - 1) {
        this.current++
      } else {
        this.current = 0
      }
    },
    prev() {
      if (this.current !== 0) {
        this.current--
      } else {
        this.current = this.items.length - 1
      }
    },
    onSubmit(successType) {
          this.loading = true;
          this.$axios({
              url: '/ajax/index.php?feadback=Y',
              method: 'post',
              params: {
                  successType: successType,
                  phone: this.phone,
                  url: location.href,
                  size: this.size,
                  img: this.img,
                  name: this.name,
                  price: this.price,
                  oldprice: this.oldprice,
                  percent: this.percent,
              }
          }).then(response => {
              if (response.status == 200 && response.data.success) {
                  this[successType] = true;
                  this.phone = "";
              }else{
                  this.$notify({
                      group: 'errors',
                      title: 'error',
                      text: 'Error feedback',
                      type: 'error'
                  });
                  console.log('Error feedback success: false')
              }
          }).catch(e => {
              this.$notify({
                  group: 'errors',
                  title: 'error',
                  text: e,
                  type: 'error'
              });
              console.log('Error: ' + e)
          })
          this.loading = false;
      },
    handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
    },
    swipeHandler (direction) {
        if(direction == "left"){
            this.next()
        }
        if(direction == "right"){
            this.prev()
        }
        // May be left / right / top / bottom
    },
  },
  created() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  }
}
</script>
<style src="vue-phone-number-input/dist/vue-phone-number-input.css"></style>
<style lang="scss">
  .ask-videoconsult{
    .flex {
      display: block;
    }
    .vue-phone-number-input {
      .select-country-container {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
      }
      .input-phone-number{
        .input-tel__label{
          margin-top: 0;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
.layout-product-gallery {
  position: relative;
}
.viewers{
  cursor: zoom-in;
}
.hidden {
  display: none;
}
.gallery {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 2em;
}
.minies{}
.mini {
  --height: 7em;
  position: relative;
  width: var(--height);
  height: var(--height);
  transition: 0.5s;
  cursor: pointer;
  opacity: 0.4;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  img {
    object-fit: contain;
  }
  &.active {
    opacity: 1;
  }
}
.photo {
  --height: 35em;
  overflow: hidden;
  /*height: var(--height);*/
}
.previews {
  transition: 1s;
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
}
.preview {
  position: relative;
  text-align: center;
  img {
    object-fit: contain;
  }
}
.video-consult {
  .name {
    margin-top: 0.5em;
    text-transform: uppercase;
    font-size: 0.875em;
  }
}
.video-popup {
  position: fixed;
  z-index: 501;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay {
  background: rgba(0, 0, 0, 0.8);
}
.close {
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 1;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: scale(1.02);
  }
}
.videoItem{
  position: relative;
}
.videoPlayButton{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
}
.videoPlayButton img{
  max-width: 40px;
}
.bgmask{
  position: absolute;
  background: rgba(0,0,0,0.1);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.vuebar-element {
  width: 100%;
  max-width: 250px;
  --height: 29em;
  /*overflow-y: auto;*/
  height: var(--height);
  margin-bottom: 23px;
}


.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0,0,0,0);
  transform: rotate3d(0,0,0,0);
  -webkit-transition:
          background-color 100ms ease-out,
          margin 100ms ease-out,
          height 100ms ease-out;
  transition:
          background-color 100ms ease-out,
          margin 100ms ease-out,
          height 100ms ease-out;
  background-color: rgba(129, 129, 129, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(129, 129, 129,.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(129, 129, 129,.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(129, 129, 129, 0);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(129, 129, 129,.5);
}

@media screen and (max-width: 767px) {
  .gallery {
    display: flex;
    flex-direction: column-reverse;
  }
  .photo {
    --height: 18em;
  }
  .minies {
    display: flex;
    flex-flow: wrap;
  }
  .dot{
    width: 4px;
    height: 4px;
    background: black;
    border-radius: 50%;
    margin: 0 4px;
    transition: .4s;
    cursor: pointer;
  }
  .mini {
    height: 10px;
    width: 20px;
    display: inline-block;
    margin-bottom: 1em;
  }
  .video-consult {
    margin-left: auto;
    width: auto;
    padding: 15px 0 0 0;
    .name {
      white-space: nowrap;
    }
  }
  .vuebar-element {
    margin-bottom: 0;
    height: 70px;
  }
  .vb-content{
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    white-space: nowrap;
    display: flex !important;
    align-items: flex-end;
    justify-content: center;
  }
  .videoItem{
    top: -18px;
    width: 26px;
    height: 10px;
  }
}

</style>
<style lang="scss">
.video-popup {
  .video {
    position: relative;
    background: #000;
    width: 80vw;
    height: 60vh;
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
</style>
<style lang="scss">
.model-viewer-popup{
  position: fixed;
  z-index: 501;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
  top:0;
  width: 100%;
  height: 100%;
}
.model-viewer-popup #model-viewer {
  width: 100%;
  height: 100%;
}
.button-view-3d{
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
}
.closeWin {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  /*opacity: 0.3;*/
  cursor:pointer;
  z-index: 2;
}
.closeWin:hover {
  opacity: 1;
}
.closeWin:before, .closeWin:after {
  position: absolute;
  left: 10px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #333;
}
.closeWin:before {
  transform: rotate(45deg);
}
.closeWin:after {
  transform: rotate(-45deg);
}
model-viewer::part(default-ar-button) {
  top: 16px;
  left: 16px;
}
#default-ar-button {
  top: 16px;
  left: 16px;
}
#lazy-load-poster {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
/* Анимации появления и исчезновения могут иметь */
/* различные продолжительности и динамику.       */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>